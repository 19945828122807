import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import UserContext from './UserContext';

function Header({ activePage }) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleSignOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
  };

  const handleGenerateEmailsClick = () => {
    if (user) {
      navigate('/generate'); // Navigate to /generate if logged in
    } else {
      navigate('/auth?mode=signup', { state: { redirectTo: '/generate' } }); // Navigate to signup with redirectTo parameter
    }
  };

  return (
    <header
      className="header"
      style={{
        backgroundColor: activePage === 'pricing' || activePage === 'emails' ? '#f3f5fc' : '#ffffff',
      }}
    >
      <div className="logo" onClick={() => handleNavigation('/')}>
        Rubybeam
      </div>
      <nav className="nav-links">
        <button
          className={`header-button ${activePage === 'home' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/')}
        >
          Home
        </button>
        <button
          className={`header-button ${activePage === 'how-it-works' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/how-it-works')}
        >
          How It Works
        </button>
        <button
          className={`header-button ${activePage === 'pricing' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/pricing')}
        >
          Pricing
        </button>
        <button
          className={`header-button ${activePage === 'contact' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/contact')}
        >
          Contact Us
        </button>
      </nav>
      <div className="auth-buttons">
        {user ? (
          <div className="user-menu">
            <button className="user-name-button">{user.firstName}</button>
            <div className="dropdown-menu">
              <button onClick={() => handleNavigation('/emails')}>New Emails</button>
              <button onClick={() => handleNavigation('/dashboard')}>Dashboard</button>
              <button onClick={() => handleNavigation('/settings')}>Settings</button>
              <button onClick={handleSignOut}>Sign out</button>
            </div>
          </div>
        ) : (
          <button
            className="header-button header-sign-in"
            onClick={() => handleNavigation('/auth?mode=login')}
          >
            Log In
          </button>
        )}
        <button
          className="header-button header-get-started"
          onClick={handleGenerateEmailsClick} // Updated to match CTA behavior
        >
          Generate Emails
        </button>
      </div>
    </header>
  );
}

export default Header;