const privacyContent = `
_Last Updated: December 31, 2024_

Rubybeam (“we,” “us,” “our”) values your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, store, and protect your information when you use our services (“Services”).

### 1. Scope and Definitions

This Privacy Policy applies to all services offered by Rubybeam (“Services”), including the use of the Rubybeam website and associated tools (“Website”). By using our Services, you consent to the practices described in this Privacy Policy.

**Definitions:**
- **Account**: A unique profile created by the user to access Rubybeam Services.
- **Personal Data**: Information that identifies or can identify an individual directly or indirectly.
- **Cookies**: Small files stored on the user’s device to track activity and improve functionality.
- **Service Provider**: Third-party companies or individuals assisting Rubybeam in providing Services.

### 2. Contact Information

If you have any questions about this Privacy Policy, please contact us at [hello@rubybeam.com](mailto:hello@rubybeam.com).

### 3. Information We Collect

We may collect the following information when you use our Services:

- **Registration Information**: Name, email address, password (encrypted), role (YouTuber or Agency), and agency name (if applicable).
- **Service Usage Data**: Emails sent through Rubybeam and associated timestamps.
- **Analytics Data**: Information collected via third-party tools like Google Analytics, such as browser type, device type, and usage patterns.

_Note_: In the future, we may collect additional information, such as IP addresses or cookie data. Any updates will be reflected in this Privacy Policy.

### 4. How We Use Your Data

We use your data to:
- Provide and improve our Services.
- Analyze service usage to optimize user experience and functionality.
- Ensure secure and reliable operation of our platform.

### 5. Data Retention

We retain your data as follows:
- **Active Accounts**: Account information and usage data are retained as long as your account remains active.
- **Deleted Accounts**: Upon deletion, your data will either be permanently erased or retained for a reasonable period as required by law or for legitimate business purposes.

### 6. Data Sharing

We do not share your data with third parties except in the following cases:
- When required by law.
- For analytics purposes with Google Analytics (subject to their privacy policies).

### 7. Cookies and Tracking

We use cookies to track user activity and improve functionality. These cookies are necessary for providing core services. Users cannot manage cookie preferences, and disabling cookies may affect the availability of some features.

### 8. Data Security

We implement robust security measures, including encryption and secure servers, to protect your personal data. While we strive to use advanced security measures, no system is completely secure.

### 9. User Rights

You have the following rights regarding your data:
- **Access and Modification**: Access and update your data via the account settings page.
- **Deletion**: Request account deletion through your settings.
- **Opt-Out Options**: Opt-out of marketing communications via account settings.

### 10. Legal Disclosures

We may disclose your personal data if required by law or in response to valid requests by public authorities.

### 11. International Compliance

Rubybeam complies with global data protection regulations, including GDPR (EU) and CCPA (California). International users acknowledge that their data will be handled in accordance with applicable laws.

### 12. Policy Updates

Rubybeam reserves the right to update this Privacy Policy at any time. Significant changes will be communicated to users via email. Continued use of our Services constitutes acceptance of the updated policy.

---

For any questions or concerns about this Privacy Policy, please contact us at [hello@rubybeam.com](mailto:hello@rubybeam.com).
`;

export default privacyContent;