import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserContext from './UserContext';
import Home from './HomePage';
import SignUp from './SignUp';
import GenerateEmails from './GenerateEmails';
import PurchaseEmails from './PurchaseEmails';
import Pricing from './Pricing';
import HowItWorks from './HowItWorks';
import Emails from './Emails';
import Dashboard from './Dashboard';
import Billing from './Billing';
import Settings from './Settings';
import InsertChannels from './InsertChannels';
import ContactUs from './ContactUs';
import EmailsHistory from './EmailsHistory';
import ResetPassword from './ResetPassword';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<SignUp />} />
          <Route path="/generate" element={<GenerateEmails />} />
          <Route path="/purchase-emails" element={<PurchaseEmails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/emails" element={<Emails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/insert-channels" element={<InsertChannels />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/emails-history" element={<EmailsHistory />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;