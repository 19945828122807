import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import "./Billing.css";

function Billing() {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state || {};
  const user = JSON.parse(localStorage.getItem("user")); // Get the logged-in user's data

  const [popupMessage, setPopupMessage] = useState(null); // State for popup message

  useEffect(() => {
    document.body.classList.add("billing-page");
    return () => document.body.classList.remove("billing-page");
  }, []);

  if (!plan) {
    return <p>No plan selected. Please go back to the Pricing page.</p>;
  }

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "subscription",
    vault: true,
  };

  // Map plan names to environment variables
  const planIds = {
    "Basic": user?.userType === "agency"
      ? process.env.REACT_APP_PLAN_AGENCY_BASIC
      : process.env.REACT_APP_PLAN_YOUTUBER_BASIC,
    "Plus": user?.userType === "agency"
      ? process.env.REACT_APP_PLAN_AGENCY_PLUS
      : process.env.REACT_APP_PLAN_YOUTUBER_PLUS,
    "Pro": user?.userType === "agency"
      ? process.env.REACT_APP_PLAN_AGENCY_PRO
      : process.env.REACT_APP_PLAN_YOUTUBER_PRO,
  };

  const selectedPlanId = planIds[plan.name]; // Get the Plan ID for the selected plan

  const handleApprove = (data) => {
    setPopupMessage("Subscription created successfully!"); // Show success message

    const subscriptionData = {
      customerId: user._id, // Use the logged-in user's ID
      plan: `${user.userType}-${plan.name.toLowerCase()}`, // Combine userType and plan name
      price: plan.price, // Numeric value of the plan price
      emails: plan.emails, // Numeric value for emails
      createdAt: new Date().toISOString(), // Timestamp
    };

    // Add youtubers count for agency users
    if (user.userType === "agency") {
      subscriptionData.youtubers = plan.youtubers || 0; // Default to 0 if not provided
    }

    fetch("/api/subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subscriptionData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.message || "Failed to save subscription");
          });
        }
        return response.json();
      })
      .then(() => {
        if (user.userType === "agency") {
          navigate("/insert-channels", { state: { plan } });
        } else if (user.userType === "youtuber") {
          navigate("/generate", { state: { plan } });
        } else {
          setPopupMessage("Unknown user type. Please contact support.");
        }
      })
      .catch((err) => {
        console.error("Error saving subscription:", err);
        setPopupMessage("Error saving subscription. Please contact support."); // Show error message
      });
  };

  return (
    <div className="billing-page-container">
      <Header activePage="emails" />
      <div className="cards-container">
        <div className="card">
          <h2>{plan.name} Plan</h2>
          <h4 className="email-amount">{plan.youtubers} YouTubers, {plan.emails} emails each</h4>
          <h3 className="price">{`$${plan.price}/mo`}</h3>
          <div className="paypal-section">
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                style={{ layout: "vertical" }}
                createSubscription={(data, actions) => {
                  return actions.subscription.create({
                    plan_id: selectedPlanId, // Use the dynamically selected Plan ID
                  });
                }}
                onApprove={(data, actions) => {
                  handleApprove(data);
                }}
                onError={(err) => {
                  setPopupMessage("Subscription creation failed. Please try again.");
                  console.error("PayPal subscription error:", err);
                }}
              />
            </PayPalScriptProvider>
          </div>
          <p className="cancel-notice">
            You can cancel anytime in your profile settings.
          </p>
        </div>
        {/* Popup Message */}
        {popupMessage && (
          <p className="popup-message">{popupMessage}</p>
        )}
      </div>
    </div>
  );
}

export default Billing;