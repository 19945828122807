import React, { useState, useEffect, useContext } from 'react';
import './SignUp.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import UserContext from './UserContext';
import privacyContent from './PrivacyContent'; // Adjust the path if necessary
import termsContent from './TermsContent'; // Adjust the path if necessary
import ReactMarkdown from 'react-markdown';



function SignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const [formState, setFormState] = useState({
    firstName: '',
    email: '',
    password: '',
    userType: 'youtuber',
    agencyName: '',
    channelUrl: '',
  });

  const [isLoginMode, setIsLoginMode] = useState(new URLSearchParams(location.search).get('mode') === 'login');
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false); // State for checkbox
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [modalContent, setModalContent] = useState(''); // State for modal content
  const [termsError, setTermsError] = useState('');




  // Extract redirectTo and plan from location.state
  const { redirectTo } = location.state || {};

  useEffect(() => {
    setIsLoginMode(new URLSearchParams(location.search).get('mode') === 'login');
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isLoginMode && !acceptTerms) { // Only check terms during signup
        setTermsError('You must accept the Terms and Conditions and Privacy Policy.');
        return;
    }

    setTermsError(''); // Clear the error if terms are accepted

    if (isLoginMode) {
        const { email, password } = formState;

        fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.error || 'Login failed');
                    });
                }
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('user', JSON.stringify(data));
                setUser(data);
                navigate(redirectTo || '/generate'); // Redirect to /generate if redirectTo is not provided
            })
            .catch((error) => {
                console.error('Login error:', error);
                alert('Error during login. Please try again.');
            });
    } else {
        fetch('/api/customer-sign-up', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formState, emailsRemaining: 3 }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.error || 'Sign-up failed');
                    });
                }
                return response.json();
            })
            .then((data) => {
                const { user } = data;
                localStorage.setItem('user', JSON.stringify(user));
                setUser(user);
                navigate(redirectTo || '/generate'); // Redirect to /generate if redirectTo is not provided
            })
            .catch((error) => {
                console.error('Sign-up error:', error);
                alert('There was an error during sign-up. Please try again.');
            });
    }
};





  const handleResetPasswordSubmit = (email) => {
    fetch('/api/send-reset-password-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('The email you entered is not associated with any account.');
          }
          throw new Error('Failed to send reset email. Please try again.');
        }
        return response.json();
      })
      .then((data) => {
        alert('Reset password email sent. Check your inbox!');
        setForgotPasswordMode(false);
        setResetError(''); // Clear error on success
      })
      .catch((error) => {
        console.error('Reset email error:', error);
        setResetError(error.message); // Set the error message
      });
  };

  const handleModalOpen = (content) => {
    setModalContent(content); // 'termsContent' or 'privacyContent'
    setShowModal(true);
  };


  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="signup-outer-container">
      <Header />
      <div className="signup-container">
        <div className="content">
          <h2 className="signup-h2">
            {forgotPasswordMode ? 'Reset Password' : isLoginMode ? 'Log In' : 'Sign Up'}
          </h2>

          {forgotPasswordMode ? (
            <div className="reset-password-container">
              <input
                type="email"
                name="resetEmail"
                value={resetEmail}
                onChange={(e) => {
                  setResetEmail(e.target.value);
                  setResetError('');
                }}
                placeholder="Enter your email"
                required
                className={`reset-email-input ${resetError ? 'input-error' : ''}`}
              />
              {resetError && <p className="error-text">{resetError}</p>}
              <button
                className="reset-password-button"
                onClick={() => handleResetPasswordSubmit(resetEmail)}
              >
                Submit
              </button>
              <button
                className="cancel-reset-button"
                onClick={() => setForgotPasswordMode(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="signup-form">
              {!isLoginMode && (
                <div className="input-row">
                  <input
                    type="text"
                    name="firstName"
                    value={formState.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    required
                    className="first-name-input"
                  />
                </div>
              )}

              <input
                type="email"
                name="email"
                value={formState.email}
                onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                placeholder="Email"
                required
                className="email-input"
              />
              <input
                type="password"
                name="password"
                value={formState.password}
                onChange={(e) => setFormState({ ...formState, password: e.target.value })}
                placeholder="Password"
                required
                className="password-input"
              />

              {!isLoginMode && (
                <>
                  <div className="user-type-row">
                    <div
                      className={`user-type-option ${formState.userType === 'youtuber' ? 'selected' : ''}`}
                      onClick={() => setFormState({ ...formState, userType: 'youtuber' })}
                    >
                      YouTuber
                    </div>
                    <div
                      className={`user-type-option ${formState.userType === 'agency' ? 'selected' : ''}`}
                      onClick={() => setFormState({ ...formState, userType: 'agency' })}
                    >
                      Agency
                    </div>
                  </div>

                  {formState.userType === 'agency' && (
                    <input
                      type="text"
                      name="agencyName"
                      value={formState.agencyName}
                      onChange={handleChange}
                      placeholder="Agency Name"
                      required
                      className="agency-name-input"
                    />
                  )}

                  {formState.userType === 'youtuber' && (
                    <input
                      type="url"
                      name="channelUrl"
                      value={formState.channelUrl}
                      onChange={handleChange}
                      placeholder="YouTube Channel URL"
                      required
                      className="channel-url-input"
                    />
                  )}
                </>
              )}

              {!isLoginMode && (
                <div className="checkbox-row">
                  <div>
                    <input
                      type="checkbox"
                      id="acceptTerms"
                      checked={acceptTerms}
                      onChange={() => {
                        setAcceptTerms(!acceptTerms);
                        setTermsError(''); // Clear the error when the checkbox is checked
                      }}
                    />
                  </div>
                  <div>
                    <span>
                      I accept the{' '}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModalOpen('termsContent');
                        }}
                        className="link-text"
                      >
                        Terms and Conditions
                      </span>{' '}
                      and{' '}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModalOpen('privacyContent');
                        }}
                        className="link-text"
                      >
                        Privacy Policy
                      </span>
                    </span>
                  </div>
                </div>
              )}

              <button type="submit">{isLoginMode ? 'Log In' : 'Sign Up'}</button>
              {termsError && <p className="error-text">{termsError}</p>}
            </form>

          )}

          {isLoginMode && !forgotPasswordMode && (
            <p className="forgot-password-text">
              Forgot your password?{' '}
              <button
                className="forgot-password-button"
                onClick={() => setForgotPasswordMode(true)}
              >
                Reset Password
              </button>
            </p>
          )}

          {!forgotPasswordMode && (
            <p className="toggle-text">
              {isLoginMode ? "Don't have an account?" : 'Already have an account?'}
              <button className="toggle-button" onClick={() => setIsLoginMode(!isLoginMode)}>
                {isLoginMode ? 'Sign Up' : 'Log In'}
              </button>
            </p>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>
              {modalContent === 'termsContent' ? 'Terms and Conditions' : 'Privacy Policy'}
            </h2>
            <div className="modal-content">
              {modalContent === 'termsContent' ? (
                <ReactMarkdown>{termsContent}</ReactMarkdown>
              ) : (
                <ReactMarkdown>{privacyContent}</ReactMarkdown>
              )}
            </div>
            <button className="modal-close-button" onClick={handleModalClose}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );




}

export default SignUp;
