const termsContent = `
_Last Updated: December 31, 2024_

The following terms and conditions govern all use of the Rubybeam website and all content, services, and products available at, or through, the Rubybeam domain rubybeam.com (the “Website”), including but not limited to the Rubybeam email outreach generation service (collectively with the Website, the “Services”).

1. **Your Rubybeam Account**
   - You are responsible for maintaining the security of your account and all activities under it.
   - Notify Rubybeam of unauthorized account use or breaches of security immediately.
   - Provide accurate information during sign-up, including your legal name and valid email address.
   - Do not violate any applicable laws while using the Services.
   - Automated account registrations are prohibited.
   - Account sharing is not allowed.

2. **Payment and Renewal**
   - Subscription fees are charged on a prepaid basis and are non-refundable.
   - Renewals are automatic unless canceled before the billing period ends.
   - Rubybeam reserves the right to modify subscription fees with prior notice.

3. **Use License**
   - You may not:
     - Build a competitive service without Rubybeam’s written consent.
     - Violate any applicable laws or regulations.
     - Exploit or harm minors or impersonate others.
     - Attempt to decompile or reverse engineer Rubybeam’s software.
     - Interfere with the proper functioning of the Website.

4. **Marketing**
   - By creating an account, you grant Rubybeam permission to use your name and logo for marketing.
   - You may revoke this permission by notifying Rubybeam via email.

5. **Confidentiality**
   - Both parties agree to protect shared Confidential Information.
   - Confidential Information is only used for purposes of this Agreement.

6. **Data Protection**
   - Rubybeam processes user data in accordance with its Privacy Policy.
   - Passwords are securely hashed, and user data is protected with reasonable measures.

7. **Intellectual Property**
   - Rubybeam retains ownership of all content and software provided.
   - Users retain ownership of content they generate but grant Rubybeam the right to use anonymized data for improvements.

8. **Limitation of Liability**
   - The Services are provided “as is” without warranties of any kind.
   - Rubybeam is not liable for indirect or consequential damages.

9. **Termination**
   - Users may terminate their subscriptions anytime, effective at the billing cycle’s end.
   - Rubybeam may terminate accounts for violations of these Terms.

10. **Governing Law and Dispute Resolution**
    - These Terms are governed by Israeli law.
    - Disputes will be resolved via arbitration under Israeli law.

11. **Changes to Terms**
    - Rubybeam may update these Terms with 15 days’ prior notice of significant changes.
    - Continued use of Services constitutes acceptance of updated Terms.

For any questions or concerns about these Terms, please contact us at hello@rubybeam.com.
`;

export default termsContent;