import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';
import Header from './Header';
import UserContext from './UserContext'; // Import your UserContext

function Pricing() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Retrieve the user from context (or localStorage/session)

  useEffect(() => {
    document.body.classList.add('pricing-page');
    return () => document.body.classList.remove('pricing-page');
  }, []);

  // Plan definitions with numeric values
  const youtuberPlans = [
    { name: 'Basic', price: 15, emails: 25 },
    { name: 'Plus', price: 20, emails: 50 },
    { name: 'Pro', price: 25, emails: 80 },
  ];

  const agencyPlans = [
    { name: 'Basic', price: 100, emails: 25, youtubers: 10 },
    { name: 'Plus', price: 300, emails: 50, youtubers: 20 },
    { name: 'Pro', price: 500, emails: 80, youtubers: 30 },
  ];

  // State to track the selected user type
  const [selectedUserType, setSelectedUserType] = useState(
    user?.userType === 'agency' ? 'Agency' : 'YouTuber'
  );

  // Dynamically set plans based on selected user type
  const [plans, setPlans] = useState(
    user
      ? user.userType === 'agency'
        ? agencyPlans
        : youtuberPlans
      : youtuberPlans
  );

  useEffect(() => {
    if (!user) {
      // Update plans when the user toggles the user type
      setPlans(selectedUserType === 'Agency' ? agencyPlans : youtuberPlans);
    }
  }, [selectedUserType, user]);

  const handleGetStarted = (plan) => {
    if (user) {
      // User is logged in, redirect to Billing page
      navigate('/billing', { state: { plan } });
    } else {
      // User is not logged in, redirect to Sign-Up page and pass the plan and redirectTo
      navigate('/auth', { state: { redirectTo: '/pricing', plan } });
    }
  };

  return (
    <div className="page-container">
      <Header activePage="pricing" />
      <h2 className="intro-h2">
        Each month, <br />
        you will receive a link to your ready-to-send emails. <br />
        Select the plan that matches your outreach goals.
      </h2>
      <p className="intro-p">
        The more emails you send, the better your chances of securing valuable sponsorship deals.
      </p>
      {!user && (
        <div className="user-type-section">
          <p className="user-type-label">I'm a:</p>
          <div className="toggle-container">
            <input
              type="radio"
              id="youtuber"
              name="package"
              value="YouTuber"
              checked={selectedUserType === 'YouTuber'}
              onChange={() => setSelectedUserType('YouTuber')}
            />
            <label htmlFor="youtuber" className="toggle-label">
              YouTuber
            </label>
            <input
              type="radio"
              id="agency"
              name="package"
              value="Agency"
              checked={selectedUserType === 'Agency'}
              onChange={() => setSelectedUserType('Agency')}
            />
            <label htmlFor="agency" className="toggle-label">
              Agency
            </label>
          </div>
        </div>
      )}
      <div className="cards-container">
        {plans.map((plan) => (
          <div className="card" key={plan.name}>
            <h2>{plan.name}</h2>
            {selectedUserType === 'YouTuber' ? (
              <h4 className="email-amount">{`${plan.emails} emails`}</h4>
            ) : (
              <h4 className="email-amount">
                {`${plan.youtubers} YouTubers, ${plan.emails} emails each`}
              </h4>
            )}
            <h3 className="price">{`$${plan.price}/mo`}</h3>
            <button
              className="buy-button"
              onClick={() => handleGetStarted(plan)} // Redirects based on login status
            >
              Get Started
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;