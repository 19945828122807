import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './IntroSection.css';

function IntroSection() {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('First 3 emails are free'); // Default text
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch user data from localStorage
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);

    // Check subscription if user is logged in
    if (storedUser) {
      fetch(`/api/subscriptions/${storedUser._id}/active`)
        .then((response) => response.json())
        .then((subscription) => {
          if (subscription.plan && subscription.plan !== 'free-trial') {
            setButtonText('Generate Emails');
          }
        })
        .catch((error) => console.error('Error fetching subscription:', error));
    }
  }, []);

  const handleClick = () => {
    if (user) {
      navigate('/generate'); // Navigate to /generate if logged in
    } else {
      navigate('/auth?mode=signup', { state: { redirectTo: '/generate' } }); // Navigate to signup with redirectTo parameter
    }
  };
  

  return (
    <div className="intro-section">
      {/* Illustration Container */}
      <div className="illustration-container">
        <img src="/HomepageVector.jpg" alt="Homepage Illustration" className="illustration" />
      </div>

      {/* Content Section */}
      <div className="content">
        <h2 className="homepage-h2">Automate your sponsorship outreach</h2>
        <p className="intro-text">
          Rubybeam helps influencer agencies save time by generating personalized sponsorship emails in minutes.
        </p>

        {/* CTA Section */}
        <div className="cta-section">
          <button className="cta-button" onClick={handleClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;