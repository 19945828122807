import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GenerateEmails.css';
import './Modal.css'; // For modal styling
import Header from './Header';
import axios from 'axios';

function GenerateEmails() {
  const [userData, setUserData] = useState(null); // Store user details, including name
  const [subscription, setSubscription] = useState(undefined); // Store subscription details, distinguish between null and undefined
  const [emails, setEmails] = useState(0); // Emails remaining
  const [youtubeUrl, setYoutubeUrl] = useState(''); // YouTube URL input
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(''); // Rotating messages
  const [isModalVisible, setModalVisible] = useState(false); // Modal visibility
  const navigate = useNavigate();

  const loadingMessages = [
    "Fetching YouTuber's Data",
    "Analyzing YouTuber's Audience",
    "Finding Aligned Brands",
    "Finding Brands' Marketing Objectives",
    "Fetching Brands' Correct Contact Details",
    "Crafting Personalized Emails",
    "Some emails are ready, transferring you to the emails page...",
  ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      fetchUserData(user._id); // Fetch user and subscription details
    } else {
      navigate('/sign-up'); // Redirect if no user data is found
    }
  }, [navigate]);

  useEffect(() => {
    if (loading) {
      let index = 0;
      setCurrentMessage(loadingMessages[index]); // Set the first message initially
  
      const interval = setInterval(() => {
        index++;
        if (index === loadingMessages.length) {
          clearInterval(interval); // Stop interval when reaching the last message
          setTimeout(() => {
            navigate('/emails'); // Navigate 25 seconds after the last message
          }, 25000); // Delay navigation by 25 seconds
        } else {
          setCurrentMessage(loadingMessages[index]);
        }
      }, 25000); // Change message every 25 seconds
  
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [loading, navigate, loadingMessages]);
  

  const fetchUserData = async (userId) => {
    try {
      const userResponse = await axios.get(`/api/users/${userId}`);
      const subscriptionResponse = await axios
        .get(`/api/subscriptions/${userId}/active`)
        .catch((error) => {
          if (error.response?.status === 404) {
            return null; // No active subscription
          }
          throw error;
        });

      setUserData(userResponse.data);
      setSubscription(subscriptionResponse?.data || null);
      setEmails(subscriptionResponse?.data?.emails || 0); // Default to 0 if no emails
    } catch (error) {
      console.error('Error fetching user or subscription data:', error);
      setFeedbackMessage('An error occurred while fetching data.');
    }
  };

  const handleGenerateEmailsClick = () => {
    setModalVisible(true); // Show the modal
  };

  const handleModalClose = () => {
    setModalVisible(false); // Hide the modal
  };

  const handleModalContinue = async () => {
    setModalVisible(false); // Hide the modal
    if (!userData || !subscription) {
      console.error('User or subscription data not found.');
      return;
    }

    if (subscription.plan === 'free-trial' && !youtubeUrl.trim()) {
      setFeedbackMessage('Please provide a valid YouTube URL.');
      return;
    }

    setLoading(true); // Start loading spinner
    try {
      const response = await axios.post('/api/generate-emails', {
        customerId: userData._id,
        youtubeUrl: youtubeUrl.trim(),
        emailsToGenerate: emails,
      });

      console.log('Emails generated:', response.data);
      setFeedbackMessage('Emails generated successfully!');
      fetchUserData(userData._id); // Refresh data
      navigate('/emails'); // Navigate to emails page
    } catch (error) {
      console.error('Error generating emails:', error);
      setFeedbackMessage('An error occurred while generating emails.');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="generate-emails-outer-container">
      <Header />
      <div className="generate-emails-container">
        {userData ? (
          subscription === undefined ? (
            <p>Loading...</p>
          ) : subscription ? (
            <>
              <h2>Welcome {userData.firstName}</h2>
              {subscription.plan !== 'free-trial' ? (
                <div className="no-subscription-container">
                  <h3>We will notify you by email once your monthly emails are ready.</h3>
                </div>
              ) : emails === 0 ? (
                <div className="no-subscription-container">
                  <h3>You've used all your free emails. Please subscribe to continue generating new emails.</h3>
                  <button onClick={() => navigate('/pricing')}>Upgrade Subscription</button>
                </div>
              ) : (
                <>
                  <h3>
                    {userData.userType === 'agency'
                      ? `You have ${emails} emails remaining for each YouTuber.`
                      : `You have ${emails} emails remaining.`}
                  </h3>
  
                  {emails > 0 && (
                    <div className="youtube-input-container">
                      <label>
                        Enter a YouTube channel URL:
                        <input
                          type="url"
                          value={youtubeUrl}
                          onChange={(e) => setYoutubeUrl(e.target.value)}
                          placeholder="https://www.youtube.com/@username"
                          required
                        />
                      </label>
                    </div>
                  )}
  
                  {emails > 0 ? (
                    loading ? (
                      <div className="loading-container">
                        <p className="loading-message">{currentMessage}</p>
                        <div className="loading-spinner"></div>
                      </div>
                    ) : (
                      <button onClick={handleGenerateEmailsClick}>Generate Emails</button>
                    )
                  ) : (
                    <button onClick={() => navigate('/pricing')}>Upgrade Subscription</button>
                  )}
                </>
              )}
              {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
            </>
          ) : (
            <div className="no-subscription-container">
              <h2>Welcome {userData.firstName}</h2>
              <h3>To start creating new emails, please purchase a subscription.</h3>
              <button onClick={() => navigate('/pricing')}>Buy Subscription</button>
            </div>
          )
        ) : (
          <p className="loading-message">Loading...</p>
        )}
      </div>
  
      {/* Modal for requirements */}
      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Before we can generate personalized emails, please ensure the following conditions are met for the YouTuber:</h3>
            <p>1. Has at least one long-form video.</p>
            <p>2. Speaks English in the videos.</p>
            <div className="modal-actions">
              <button onClick={handleModalClose} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleModalContinue} className="continue-button">
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// mark
export default GenerateEmails;